let burger = document.querySelector(".header__burger")
let body = document.querySelector("body")
let header = document.querySelector("header")
let navLinks = document.querySelectorAll(".nav__link")
let navBg = document.querySelector(".nav__bg")

if(burger) {
    burger.onclick = function () {
        header.classList.toggle("_open")
        body.classList.toggle("fixed-body")
    }
}

let lang = document.querySelector(".header__lang")
let langShow = document.querySelector(".header__lang-show")
let langBg = document.querySelector(".header__lang-bg")

if(window.innerWidth>992){
    if(lang){
        lang.onmouseenter = function () {
            lang.classList.add("header__lang--active")
        }
        lang.onmouseleave = function () {
            lang.classList.remove("header__lang--active")
        }
    }

    if(navLinks.length>0){
        navLinks.forEach(el => {
            el.onmouseenter = function () {
                el.classList.add("nav__link--active")
            }
            el.onmouseleave = function () {
                el.classList.remove("nav__link--active")
            }
        })
    }
} else{
    if(langShow){
        langShow.onclick = function () {
            lang.classList.add("header__lang--active")
        }
        langBg.onclick = function () {
            lang.classList.remove("header__lang--active")
        }
    }
    if(navLinks.length>0){
        navLinks.forEach(el => {
            el.onclick = function () {
                el.classList.add("nav__link--active")
            }
            navBg.onclick = function (e) {
                e.stopPropagation()
                navLinks.forEach(el => {
                    el.classList.remove("nav__link--active")
                })
            }
        })
    }
}
